// App.js
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import routesConfig from "./Routes.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  const router = createBrowserRouter(routesConfig);

  return (
    <>
      <Toaster />
      <FloatingWhatsApp
        // onClick={() =>
        //   (window.location.href = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=`)
        // }
        phoneNumber={`${process.env.REACT_APP_WHATSAPP_NUMBER}`}
        accountName={`${process.env.REACT_APP_WHATSAPP_ACCOUNT_NAME}`}
        statusMessage="Type Here..."
        allowEsc
        chatboxHeight={300}
        chatboxStyle
        allowClickAway
        notification={false}
        notificationSound={false}
        chatMessage={`Hi There, You can contact us here for any suggestion or query!`}
      />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
