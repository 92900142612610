import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Loader from "./superComponents/loader/Loader";
import App from "./App";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "apollo-require-preflight": "true",
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

const Main = () => {
  return (
    // <div id='google_translate_element'>
      <React.StrictMode>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <PersistGate loading={null} persistor={persistor}>
              <Suspense fallback={<Loader />}>
                <App />
              </Suspense>
            </PersistGate>
          </ApolloProvider>
        </Provider>
      </React.StrictMode>
    // </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);

reportWebVitals();
