// CustomRoute.js
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CustomRoute = ({ element, allowedRoles }) => {
  const auth = useSelector((state) => state.auth);

  // const auth = {
  //   isLogin: true,
  //   currentUser: {
  //     role: "admin",
  //   },
  // };

  if (!auth.isLogin) {
    // Redirect to the login page if user is not authenticated
    return <Navigate to="/" />;
  }

  // Check if the user's role is allowed to access the route
  if (allowedRoles.some((role) => auth.currentUser?.role.includes(role))) {
    // if (allowedRoles.includes(user.role)) {
    return element;
  } else {
    // Redirect to a forbidden page or display an error message
    return <Navigate to="/forbidden" />;
  }
};

export default CustomRoute;
