import React, { lazy } from "react";
import CustomRoute from "./CustomRoute";
import { USER_ROLE } from "./constants.js";
import { Navigate } from "react-router-dom";

const Views = {
  IndexPage: lazy(() => import("./components/views/IndexPage.js")),
  About: lazy(() => import("./components/views/About.js")),
  ContactUs: lazy(() => import("./components/views/ContactUs.js")),
  AwardList: lazy(() => import("./components/views/AwardList.js")),
  Store: lazy(() => import("./components/views/Store.js")),
  PrivacyPolicy: lazy(() => import("./components/views/PrivacyPolicy.js")),
  RefundPolicy: lazy(() => import("./components/views/RefundPolicy.js")),
  TermsandConditions: lazy(() =>
    import("./components/views/TermsandConditions.js")
  ),
};

const DefaultPages = {
  ErrorPage: lazy(() => import("./defaultPages/ErrorPage.js")),
  Forbidden: lazy(() => import("./defaultPages/Forbidden.js")),
  Login: lazy(() => import("./defaultPages/Login.js")),
  ForgotPassword: lazy(() => import("./defaultPages/ForgotPassword.js")),
  ResetPassword: lazy(() => import("./defaultPages/ResetPassword.js")),
  RegistrationForm: lazy(() => import("./defaultPages/RegistrationForm.js")),
  ComingSoon: lazy(() => import("./defaultPages/ComingSoon.js")),
  BlueDart: lazy(() => import("./defaultPages/BlueDart.js")),
};

// customer panel routes

const CustomerLayout = lazy(() =>
  import("./components/customer/CustomerLayout.js")
);

const CustomerPages = {
  ProfilePage: lazy(() => import("./components/customer/pages/ProfilePage.js")),
  ProductPage: lazy(() => import("./components/customer/pages/ProductPage.js")),
  TaskList: lazy(() => import("./components/customer/pages/TaskList.js")),
  ResultTable: lazy(() => import("./components/customer/pages/ResultTable.js")),
  Invoice: lazy(() =>
    import("./components/customer/pages/invoice/Invoice.jsx")
  ),
  OrderList: lazy(() => import("./components/customer/pages/OrderList.js")),
  PaymentPage: lazy(() =>
    import("./components/customer/pages/HandlePaymentPage.js")
  ),
};

// admin panel routes

const AdminLayout = lazy(() =>
  import("./components/admin/adminLayout/AdminLayout.js")
);
const Dashboard = lazy(() =>
  import(
    "./components/admin/adminLayout/rightsidecontentPage/dashboard/Dashboard.js"
  )
);

const User = {
  List: lazy(() =>
    import("./components/admin/adminLayout/rightsidecontentPage/user/List.js")
  ),
};
const Invoice = lazy(() =>
  import(
    "./components/admin/adminLayout/rightsidecontentPage/invoice/Invoice.jsx"
  )
);
const Product = {
  Add: lazy(() =>
    import("./components/admin/adminLayout/rightsidecontentPage/product/Add.js")
  ),
};

const Task = {
  Add: lazy(() =>
    import("./components/admin/adminLayout/rightsidecontentPage/tasks/Add.js")
  ),
  List: lazy(() =>
    import("./components/admin/adminLayout/rightsidecontentPage/tasks/List.js")
  ),
  ResultTable: lazy(() =>
    import(
      "./components/admin/adminLayout/rightsidecontentPage/tasks/ResultTable.js"
    )
  ),
};

const Order = {
  List: lazy(() =>
    import("./components/admin/adminLayout/rightsidecontentPage/order/List.js")
  ),
  BlueDart: lazy(() =>
    import(
      "./components/admin/adminLayout/rightsidecontentPage/order/BlueDart.js"
    )
  ),
};

const routesConfig = [
  {
    path: "/",
    element: <Views.IndexPage />,
  },

  {
    path: "/about-us",
    element: <Views.About />,
  },
  {
    path: "/contact-us",
    element: <Views.ContactUs />,
  },
  {
    path: "/privacy-policy",
    element: <Views.PrivacyPolicy />,
  },
  {
    path: "/refund-policy",
    element: <Views.RefundPolicy />,
  },
  {
    path: "/terms-of-service",
    element: <Views.TermsandConditions />,
  },
  // {
  //   path: "/award-list",
  //   element: <Views.AwardList />,
  // },

  {
    path: "/online-shop",
    element: <Views.Store />,
  },
  {
    path: "/forbidden",
    element: <DefaultPages.Forbidden />,
  },

  {
    path: "/register",
    element: <DefaultPages.RegistrationForm />,
  },
  {
    path: "/coming_soon",
    element: <DefaultPages.ComingSoon />,
  },
  {
    path: "/blue_dart",
    element: <DefaultPages.BlueDart />,
  },
  {
    path: "/login",
    element: <DefaultPages.Login />,
  },
  {
    path: "/forgot-password",
    element: <DefaultPages.ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <DefaultPages.ResetPassword />,
  },
  {
    path: "/page-not-found",
    element: <DefaultPages.ErrorPage />,
  },

  {
    path: "*",
    element: <Navigate to="/page-not-found" />,
  },

  {
    element: (
      <CustomRoute element={<AdminLayout />} allowedRoles={[USER_ROLE.Admin]} />
    ),
    children: [
      {
        path: "/admin/*",
        children: [
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "invoice/:invoiceID",
            element: <Invoice />,
          },
          {
            path: "user",
            children: [
              {
                path: "list",
                element: <User.List />,
              },
            ],
          },
          {
            path: "product",
            children: [
              {
                path: "add",
                element: <Product.Add />,
              },
            ],
          },
          {
            path: "task",
            children: [
              {
                path: "add",
                element: <Task.Add />,
              },
              {
                path: "list",
                element: <Task.List />,
              },
              {
                path: "result/:taskID",
                element: <Task.ResultTable />,
              },
            ],
          },
          {
            path: "order",
            children: [
              {
                path: "list/:taskID",
                element: <Order.List />,
              },
              {
                path: "blue_dart/:orderID",
                element: <Order.BlueDart />,
              },
            ],
          },
          {
            path: "*",
            element: <Navigate to="/page-not-found" replace={true} />,
          },
        ],
      },
    ],
  },

  {
    element: (
      <CustomRoute
        element={<CustomerLayout />}
        allowedRoles={[USER_ROLE.Customer]}
      />
    ),
    children: [
      {
        path: "/me/*",
        children: [
          {
            path: "profile",
            element: <CustomerPages.ProfilePage />,
          },
          {
            path: "result/:TaskID",
            element: <CustomerPages.ResultTable />,
          },
          {
            path: "invoice/:invoiceID",
            element: <CustomerPages.Invoice />,
          },
          {
            path: "orders",
            element: <CustomerPages.OrderList />,
          },
          {
            path: "tasklist",
            element: <CustomerPages.TaskList />,
          },
          {
            path: "payment-page",
            element: <CustomerPages.PaymentPage />,
          },
          {
            path: "task/:taskId",
            element: <CustomerPages.ProductPage />,
          },

          {
            path: "*",
            element: <Navigate to="/page-not-found" replace={true} />,
          },
        ],
      },
    ],
  },
];

export default routesConfig;
